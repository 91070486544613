<template>
  <div class="dialogContainer" @click="closeDialog">
    <div class="dialog">
      <div class="dealerSearchContainer">
        <div style="padding:5px;">
          <v-text-field
            @keyup="searchDealer"
            label="Dealer name"
            v-model="dealerSearchText"
          ></v-text-field>
        </div>
        <div class="dealerSearchResults">
          <div
            @click="() => updateDealerData(item)"
            class="dealerContainer"
            v-for="(item, index) in availableDealers"
            :key="index"
          >
            {{ item["dealerName"] }}
          </div>
        </div>
      </div>
      <div class="dealerChartContainer">
        <CarComposition
          :dealer-data="dealerData"
          :category="dealerCategory"
        ></CarComposition>
      </div>
    </div>
  </div>
</template>
<script>
import CarComposition from "../AnalyticsCharts/CarComposition/CarComposition.vue";
export default {
  name: "DealerCarStatsDialog",
  components: { CarComposition },
  data() {
    return {
      dealerSearchText: "",
      availableDealers: [],
      dealerData: null,
      dealerCategory: "",
    };
  },
  props: ["dealersData"],
  methods: {
    updateDealerData(item) {
      console.log(`Updating dealer data`);
      console.log(item);
      this.dealerData = item;
    },

    searchDealer() {
      let searchContent = [];
      for (let dealer of this.dealersData["value"]) {
        let dealerName = dealer["dealerName"] || "";
        if (
          dealerName
            .toLowerCase()
            .search(this.dealerSearchText.toLowerCase()) != -1
        )
          searchContent.push(dealer);
      }
      this.availableDealers = searchContent;
    },

    closeDialog(e) {
      if (e.target === document.getElementsByClassName("dialogContainer")[0]) {
        this.$emit("close");
      }
    },
  },
  beforeMount() {
    this.availableDealers = this.dealersData["value"];
    this.dealerData = this.dealersData["value"][0];
    this.dealerCategory = this.dealersData["key"];
  },
  mounted() {
    console.log(this.dealersData);
  },
};
</script>
<style lang="scss">
@import "./DealerCarStatsDialog.scss";
</style>
