<template>
  <div id="pieChartContainer">
    <div class="pieChartTitleContainer">
      <div class="title">{{ this.dealerData["dealerName"] }}</div>
      <div>{{ `Category : ${this.category} car range` }}</div>
    </div>
    <div class="pieChartHolder" id="pieChartHolder">
      <svg class="pieChartDealerStats"></svg>
    </div>
  </div>
</template>
<script>
import * as d3 from "d3v4";
export default {
  name: "CarComposition",
  props: ["dealerData", "category"],
  data() {
    return {
      chartContainer: null,
      width: -1,
      height: -1,
    };
  },
  methods: {
    init() {
      this.chartContainer = d3.select(".pieChartDealerStats");
      let container = document.getElementById("pieChartContainer");
      this.width = container.clientWidth - 100;
      this.height = container.clientHeight - 100;
      this.chartContainer.attr("width", this.width);
      this.chartContainer.attr("height", this.height);
      this.chartContainer = this.chartContainer
        .append("g")
        .attr(
          "transform",
          `translate(${this.width / 2},${(this.height + 50) / 2})`
        );
      this.chartContainer
        .append("circle")
        .attr("class", "innerCircleLegend")
        .attr("cx", 0)
        .attr("cy", 0)
        .attr("r", Math.min(this.width * 0.21, this.height * 0.21))
        .attr("fill", "red");

      this.chartContainer
        .append("foreignObject")
        .attr("x", -40)
        .attr("y", -40)
        .attr("width", 80)
        .attr("height", 80)
        .html(function() {
          return `
                        <div class="innerCircleLegendText">
                            Dealers Car Composition
                        </div>
                    `;
        });

      d3.select(".pieChartDealerStats")
        .append("foreignObject")
        .attr("x", 0)
        .attr("y", 10)
        .attr("width", this.width)
        .attr("height", 50)
        .html(function() {
          return `
                        <div style="display:flex;justify-content:flex-end">
                            <div style="display:flex;flex-direction:column">
                                <div style="display:flex;align-items:center;">
                                    <div style="height:10px;width:10px;background-color:#1f77b4"></div>
                                    <span style="margin-left:10px;color:#635F5D;font-weight:bold"> Active Cars </span>
                                </div>
                                <div style="display:flex;align-items:center;">
                                    <div style="height:10px;width:10px;background-color:#ff7f0e"></div>
                                    <span style="margin-left:10px;color:#635F5D;font-weight:bold"> Sold Cars  </span>
                                </div> 
                            </div>
                        </div>
                    `;
        });
      this.pieChartHandler();
    },
    pieChartHandler() {
      var domainVsColorStroke = {
        active: "#1f77b4",
        sold: "#ff7f0e",
      };

      var colorStrokeVsFillColor = {
        "#1f77b4": "#1f77b454",
        "#ff7f0e": "#ff7f0e54",
      };
      let pie = d3.pie().value(d => d.value);
      let color = d3
        .scaleOrdinal()
        .domain(["active", "sold"])
        .range(["#1f77b4", "#ff7f0e"]);
      let dataForPie = d3.entries(this.dealerData["composition"]);
      let pieWithData = pie(dataForPie);
      let arcProducer = d3
        .arc()
        .innerRadius(Math.min(this.width * 0.22, this.height * 0.22))
        .outerRadius(Math.min(this.width * 0.48, this.height * 0.48));
      let pieChart = this.chartContainer.selectAll("path").data(pieWithData);

      pieChart.exit().remove();

      pieChart
        .enter()
        .append("path")
        .on("mouseover", onMouseOver)
        .on("mouseout", onMouseLeave)
        .merge(pieChart)
        .attr("d", arcProducer)
        .attr("fill", function(d) {
          return color(d.data.key);
        })
        .attr("stroke", "white")
        .style("stroke-width", "3px")
        .style("opacity", 1);

      let chartText = this.chartContainer
        .selectAll(".pieText")
        .data(pieWithData);

      chartText.exit().remove();

      chartText
        .enter()
        .append("text")
        .attr("class", "pieText")
        .merge(chartText)
        .text(d =>
          d.data.value > 1 ? `${d.data.value} Cars` : `${d.data.value} Car`
        )
        .attr("transform", function(d) {
          return "translate(" + arcProducer.centroid(d) + ")";
        })
        .style("text-anchor", "middle")
        .attr("fill", "white")
        .style("stroke-width", "1px")
        .style("font-size", 14);

      let self = this;
      function onMouseOver(d) {
        d3.select(this)
          .transition()
          .duration(500)
          .ease(d3.easeBounce)
          .attr(
            "d",
            d3
              .arc()
              .innerRadius(Math.min(self.width * 0.22, self.height * 0.22))
              .outerRadius(Math.min(self.width * 0.5, self.height * 0.5))
          );

        let domain = d.data.key;
        let stroke = domainVsColorStroke[domain];
        let fill = colorStrokeVsFillColor[stroke];

        d3.select(".innerCircleLegend")
          .style("stroke", stroke)
          .style("fill", fill);
        document.getElementsByClassName(
          "innerCircleLegendText"
        )[0].innerHTML = domain.toUpperCase();
      }

      function onMouseLeave() {
        d3.select(this)
          .transition()
          .duration(500)
          .ease(d3.easeBounce)
          .attr(
            "d",
            d3
              .arc()
              .innerRadius(Math.min(self.width * 0.22, self.height * 0.22))
              .outerRadius(Math.min(self.width * 0.48, self.height * 0.48))
          );

        d3.select(".innerCircleLegend")
          .style("stroke", "#ff0000")
          .style("fill", "#ff000080");
        document.getElementsByClassName("innerCircleLegendText")[0].innerHTML =
          "Dealers Car Composition";
      }
    },
  },
  beforeUpdate() {
    console.log("Going to update....");
    console.log(this.dealerData);
    this.pieChartHandler();
  },
  mounted() {
    console.log(this.dealerData);
    this.init();
  },
};
</script>
<style lang="scss">
@import "./CarComposition.scss";
</style>
