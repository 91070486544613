<template>
  <div :id="computedChartContainerId" class="DealerCarStatsChartContainer">
    <DealerCarStatsDialog
      v-if="showCarStatsDialog"
      :dealers-data="propsForDialog"
      v-on:close="closeDialog"
    ></DealerCarStatsDialog>
    <v-snackbar v-model="snackbar" :timeout="4000" top>
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <div class="calenderContainerDealerCarStats">
      <CalendarDialog
        v-on:callback="startDateCallback"
        :default-date="startDate"
        field-label="Starting Date"
        :controller="startDateController"
      ></CalendarDialog>
      <CalendarDialog
        v-on:callback="endDateCallback"
        :default-date="endDate"
        field-label="Ending Date"
        :controller="endDateController"
      ></CalendarDialog>
      <div class="inputField">
        <v-checkbox
          @change="carStatusCallback"
          v-model="carStatus"
          label="Active Cars"
          value="active"
        ></v-checkbox>
        <v-checkbox
          @change="carStatusCallback"
          v-model="carStatus"
          label="Sold Cars"
          value="sold"
        ></v-checkbox>
      </div>
      <div style="width:200px">
        <v-autocomplete
          @change="handleDealerSearch"
          :items="allDealers"
          v-model="dealerUnderSearch"
          label="Dealer Name"
        ></v-autocomplete>
      </div>
    </div>
    <svg height="550" width="1000" :id="computedChartId"></svg>
  </div>
</template>
<script>
import * as d3 from "d3v4";
import getAxios from "../../../../services/axios-get";
import DealerCarStatsDialog from "../../../components/DealerCarStatsDialog/DealerCarStatsDialog";
import CalendarDialog from "../../CalendarDialog/CalendarDialog.vue";
export default {
  name: "DealerCarStats",
  components: { CalendarDialog, DealerCarStatsDialog },
  props: {
    id: String,
  },
  data() {
    return {
      propsForDialog: null,
      showCarStatsDialog: false,
      barWidth: -1,
      snackbar: false,
      snackMessage: "",
      width: null,
      height: 350,
      margin: {
        top: 70,
        left: 100,
        right: 100,
        bottom: 100,
      },
      dealerCarStatsData: null,
      dealerCarStatsContainer: null,
      xAxisGroup: null,
      yAxisGroup: null,
      xScale: null,
      yScale: null,
      startDate: "2020-12-01",
      endDate: "2021-6-01",
      startDateController: {},
      endDateController: {},
      carStatus: ["active", "sold"],
      yAxisLegend: "Number of Dealers",
      xAxisLegend: "Cars Count Range",
      allDealers: [],
      dealerUnderSearch: "",
    };
  },
  computed: {
    computedChartId: function() {
      return "DealerCarStatsChart" + this.id;
    },
    computedChartContainerId: function() {
      return "DealerCarStatsChartContainer" + this.id;
    },
  },
  methods: {
    closeDialog() {
      this.showCarStatsDialog = false;
      this.dealerUnderSearch = "";
      console.log("closing...");
    },

    showSnackBar(message) {
      this.snackMessage = message;
      this.snackbar = true;
    },

    carStatusCallback() {
      if (this.carStatus.length == 0) {
        this.showSnackBar("Kindly select atleast one status!!");
        this.carStatus = ["active"];
      }
      this.getDealerCarStatsByTime("update");
    },

    startDateCallback(startDate) {
      if (new Date(startDate) > new Date(this.endDate)) {
        this.showSnackBar("Start Date should be less than End Date!!");
        this.startDateController.updateDateInCalendar(this.startDate);
        return;
      }
      this.startDate = startDate;
      this.getDealerCarStatsByTime("update");
    },

    endDateCallback(endDate) {
      if (new Date(endDate) < new Date(this.startDate)) {
        this.showSnackBar("End Date should be greater than Start Date!!");
        this.endDateController.updateDateInCalendar(this.endDate);
        return;
      }
      this.endDate = endDate;
      this.getDealerCarStatsByTime("update");
    },

    initGraph() {
      let chart = document.getElementById(this.computedChartContainerId);
      this.width = chart.clientWidth - 50;
      d3.select("#" + this.computedChartId).attr("width", this.width);
      let svg = d3.select("#" + this.computedChartId);
      this.dealerCarStatsContainer = svg
        .append("g")
        .attr(
          "transform",
          `translate(${this.margin.left}, ${this.margin.top})`
        );
      this.xAxisGroup = this.dealerCarStatsContainer.append("g");
      this.yAxisGroup = this.dealerCarStatsContainer.append("g");
      this.renderChart();
    },

    loadXAxis(data) {
      this.xScale = d3
        .scaleBand()
        .range([0, this.width - 120])
        .domain(data.map(d => d.key));
      this.xAxisGroup
        .attr("class", `x-axis-dealer-stats-bar-chart-${this.id}`)
        .attr("transform", `translate(0,${this.yScale(0)})`)
        .call(
          d3
            .axisBottom(this.xScale)
            .tickSize(-this.height)
            .tickPadding([10])
        );
    },

    loadYAxis(data) {
      this.yScale = d3
        .scaleLinear()
        .range([this.height, 0])
        .domain(d3.extent(data, d => d.value.length))
        .nice();
      this.yAxisGroup
        .attr("class", `y-axis-dealer-stats-bar-chart-${this.id}`)
        .call(d3.axisLeft(this.yScale).tickSize(-this.width + 120));
    },

    loadLegend() {
      d3.select(`.x-axis-legend-dealerCarStats-${this.id}`).remove();
      d3.select(`.y-axis-legend-dealerCarStats-${this.id}`).remove();

      this.dealerCarStatsContainer
        .append("text")
        .attr("class", `x-axis-legend x-axis-legend-dealerCarStats-${this.id}`)
        .attr(
          "transform",
          `translate( ${(this.width - 120) / 2}, ${
            this.width < 800 ? this.height + 100 : this.height + 50
          })`
        )
        .text(this.xAxisLegend);

      this.dealerCarStatsContainer
        .append("text")
        .attr("class", `y-axis-legend y-axis-legend-dealerCarStats-${this.id}`)
        .attr("transform", `rotate(-90)`)
        .attr("x", -this.height / 2 - 50)
        .attr("y", -60)
        .text(this.yAxisLegend);
    },

    loadBarChart(data) {
      let self = this;
      let z = d3.scaleOrdinal(d3.schemeCategory10).domain(data.map(d => d.key));
      let bars = this.dealerCarStatsContainer
        .selectAll(`.dealerCarStats-${this.id}`)
        .data(data, d => {
          return d.key;
        });

      bars.exit().remove();

      bars
        .enter()
        .append("rect")
        .on("click", onClick)
        .on("mouseover", onMouseOver)
        .on("mouseout", onMouseLeave)
        .attr("class", `dealerCarStats-${this.id}`)
        .merge(bars)
        .attr("x", d => this.xScale(d["key"]) + 10)
        .attr("y", d => this.yScale(d["value"].length))
        .attr("height", d => this.height - this.yScale(d["value"].length))
        .attr("width", this.xScale.bandwidth() - 10)
        .attr("fill", d => z(d["key"]));

      function onClick(d) {
        console.log(self.dealerCarStatsData + "" + d);
        self.propsForDialog = d;
        self.showCarStatsDialog = true;
      }

      function onMouseOver(d) {
        //let category = this.parentElement.getAttribute("category");
        let x = parseInt(d3.select(this).attr("x"));
        let xAxis = x + 20;
        let yAxis = parseInt(d3.select(this).attr("y")) - 45;
        if (xAxis + 100 > self.width - 120) {
          yAxis = yAxis + 50;
          xAxis = xAxis - 200;
        }
        self.dealerCarStatsContainer
          .append("foreignObject")
          .attr(
            "class",
            `DealerCarStatsTooltipContainer-${self.id} DealerCarStatusTooltipContainer`
          )
          .attr("x", xAxis)
          .attr("y", yAxis)
          .attr("width", 100)
          .attr("height", 30)
          .html(function() {
            return `
                            <div class="DealerCarStatsTooltip">
                                <span style="font-size:13px;color:grey">Count : ${d.value.length} </span>
                            </div>
                        `;
          });
      }

      function onMouseLeave() {
        d3.selectAll(`.DealerCarStatsTooltipContainer-${self.id}`).remove();
      }
    },

    handleDealerSearch() {
      let dealerName = this.dealerUnderSearch;
      console.log(dealerName);
      let data = d3.entries(this.dealerCarStatsData);
      let d = {};
      for (let group of data) {
        let isDealerFound = false;
        for (let dealer of group["value"]) {
          if (dealer["dealerName"] == dealerName) {
            d["key"] = group["key"];
            d["value"] = [dealer];
            isDealerFound = true;
            break;
          }
        }
        if (isDealerFound) {
          break;
        }
      }
      this.propsForDialog = d;
      this.showCarStatsDialog = true;
    },

    loadDealerSearch(data) {
      let dealers = [];
      data.forEach(group => {
        group["value"].forEach(dealer => {
          dealers.push(dealer["dealerName"]);
        });
      });
      this.allDealers = dealers;
    },

    renderChart() {
      let data = d3.entries(this.dealerCarStatsData);
      this.loadDealerSearch(data);
      console.log(data);
      this.loadYAxis(data);
      this.loadXAxis(data);
      this.loadLegend();
      this.loadBarChart(data);
      if (this.width < 800) {
        d3.selectAll(
          `.x-axis-dealer-stats-bar-chart-${this.id} .tick text`
        ).attr("transform", "translate(-10, 50)rotate(-90)");
      } else {
        d3.selectAll(
          `.x-axis-dealer-stats-bar-chart-${this.id} .tick text`
        ).attr("transform", "translate(0, 0)rotate(0)");
      }
    },

    getDealerCarStatsByTime(mode) {
      let self = this;
      let url =
        process.env.VUE_APP_ANALYTICS_URL + "/api/v1/analytics/dealerCarStats";
      let params = {
        startDate: this.startDate,
        endDate: this.endDate,
        carStatus:
          this.carStatus.length == 1
            ? this.carStatus[0]
            : this.carStatus.join(","),
      };
      getAxios(url, params)
        .then(function(res) {
          self.dealerCarStatsData = res["data"]["data"];
          if (mode == "init") {
            self.initGraph();
          } else {
            self.renderChart();
          }
        })
        .catch(function(err) {
          console.log(err);
          self.showSnackBar("Error Occurred While Loading Chart!!");
        });
    },

    updateSVG() {
      let chart = document.getElementById(this.computedChartContainerId);
      let currentWidth = chart.clientWidth - 50;
      this.width = currentWidth;
      d3.select("#" + this.computedChartId).attr("width", currentWidth);
      this.renderChart();
    },

    resizeChart() {
      window.addEventListener("resize", this.updateSVG);
    },
  },
  beforeMount() {
    let today = new Date();
    let before5Months = new Date();
    before5Months.setMonth(before5Months.getMonth() - 3);
    this.endDate = today.toISOString().substr(0, 10);
    this.startDate = before5Months.toISOString().substr(0, 10);
  },
  mounted() {
    this.getDealerCarStatsByTime("init");
    this.resizeChart();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateSVG);
  },
};
</script>
<style lang="scss">
@import "./DealerCarStats.scss";
</style>
